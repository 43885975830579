import * as React from 'react'
import { graphql } from 'gatsby';
import { blockHeaderResolver } from '../helpers';
import GeneralLayout from '../components/layouts/general'

import LiveDemoForm from '../components/blocks/static/LiveDemoForm';


const IndexPage = (props: any) => {
  const { data } = props;
  const { 
    htmlTitle = '',
    metaDescription = '',
    metaTags,
    navBarColor,
    navBarStickyColor,
    logo, 
    header,
    introParagraph, 
    challengesList, 
    resultsList,
    solution,
    challenges
  } = data.page;

  const challengesTransformed = challenges?.internal?.content ? challenges.internal.content.split('\n').map((l: any, idx: number) => <p key={idx}>{l}</p>) : null;
  const solutionTransformed = solution?.internal?.content ? solution.internal.content.split('\n').map((l: any, idx: number) => <p key={idx}>{l}</p>) : null;
  const logoUrl = logo?.file?.url;

  const Header = blockHeaderResolver(header?.blockName[0]);
  if ( header?.blockName[0]) {
    
  }

  return (
  <GeneralLayout
      htmlTitle={htmlTitle}
      metaDescription={metaDescription?.metaDescription}
      metaTags={metaTags}
      navBarColor={navBarColor}
      navBarStickyColor={navBarStickyColor}
  >       
      <Header
        {...header}
      />
      <section id="section-mission" className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 mx-auto">
              <div className="row">
                <div className="col-md-4">
                  <img 
                    src={logoUrl}
                    className="align-middle"
                    data-aos="fade-up"
                    style={{width: '90%'}}
                  />
                </div>
                <div className="col-md-8">
                  <p className="lead">{introParagraph?.introParagraph}</p>
                </div>
              </div>
              <br />
              <h2>Challenge</h2>
              <div className="">
                {challengesTransformed}
              </div>
              
              <br />
              <ul className="list-unstyled">
                { challengesList?.map((l: any, idx: number) => (
                  <li key={idx}>
                    <i className="ti-check text-success mr-2"></i>
                    <span>{l}</span>
                  </li>
                ))}
              </ul>
              <br />
              <h2>Solution</h2>
              <div>
                {solutionTransformed}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-results" className="section bg-gray">
        <div className="container">
        <div className="row gap-y align-items-center">
            <div className="col-md-7 mx-auto">

              <h2>Results</h2>
              <br />

              { resultsList?.map((l: any, idx: number) => (
                  <p key={idx}>
                    <i className="ti-check text-success mr-2"></i>
                    <strong>{l}</strong>
                  </p>
                ))}
            </div>
        </div>

        </div>  
      </section>

      <LiveDemoForm />
  </GeneralLayout>
  );
}


export default IndexPage

export const pageQuery = graphql`
  query CaseStudy ($slug: String){
    page: contentfulCaseStudy (slug: {eq: $slug}) {
      id slug 
      metaDescription
      metaTags
      navBarColor
      navBarStickyColor
  
      ...BlockHeaderFragmentCaseStudy
      challengesList resultsList
      introParagraph {
         introParagraph  
      }             
      challenges {
        internal {
          content
          description
          ignoreType
          mediaType
        }
      }
      solution {
        internal {
          content
          description
          ignoreType
          mediaType
        }
      }
      logo {
        fluid {
          base64
        }
        file {
          url
        }
        fixed {
          base64
        }
      }
    }
  }
`